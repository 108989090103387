import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, menuLinks }) => (
  <header
    
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        //padding: `1rem 0.5rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
      {/*  <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >*/}
          {siteTitle}
       {/* </Link>*/}
      </h1>
      <nav>
        <ul style={{ display: "flex", flex: 2, flexWrap:"wrap" }}>
          {menuLinks.map(link => (
            <li
              key={link.name}
              style={{
                listStyleType: `none`,
                padding: `0.5rem`,
              }}
            >
              <Link style={{ color: `white` }} to={link.link}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
